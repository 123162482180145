import { usePopover } from 'lib/hooks'
import { capitalizeFirstLetter } from 'lib/utils'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, useState } from 'react'

const timeoutLength = 300

const RegionMenu: FC<Props> = ({ items }) => {
  const router = useRouter()
  const { ref, isOpen, setIsOpen } = usePopover(false)
  const [isHoveringButton, setIsHoveringButton] = useState(false)
  const [isHoveringMenu, setIsHoveringMenu] = useState(false)

  const open = isHoveringButton || isHoveringMenu || isOpen

  return (
    <div className='relative ml-4'>
      {/* <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" --> */}
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        aria-owns={open ? 'simple-menu' : null}
        aria-haspopup={true}
        onMouseEnter={() => setIsHoveringButton(true)}
        onMouseLeave={() => {
          setTimeout(() => {
            setIsHoveringButton(false)
          }, timeoutLength)
        }}
        type='button'
        className='inline-flex items-center text-base font-medium text-gray-500 bg-white rounded-md group hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'>
        <span className='uppercase text-primary-500'>
          {items.find((i) => i.href === decodeURI(router.asPath))?.label}
        </span>
        {/* <!--
Heroicon name: solid/chevron-down

Item active: "text-gray-600", Item inactive: "text-gray-400"
--> */}
        <svg
          className='w-5 h-5 ml-2 text-gray-400 group-hover:text-gray-500'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
          fill='currentColor'
          aria-hidden='true'>
          <path
            fillRule='evenodd'
            d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
            clipRule='evenodd'
          />
        </svg>
      </button>

      {/* <!--
Flyout menu, show/hide based on flyout menu state.

Entering: "transition ease-out duration-200"
From: "opacity-0 translate-y-1"
To: "opacity-100 translate-y-0"
Leaving: "transition ease-in duration-150"
From: "opacity-100 translate-y-0"
To: "opacity-0 translate-y-1"
--> */}
      {open && (
        <div
          ref={ref}
          id='simple-menu'
          onMouseEnter={() => setIsHoveringMenu(true)}
          onMouseLeave={() => {
            setTimeout(() => {
              setIsHoveringMenu(false)
            }, timeoutLength)
          }}
          style={{ maxHeight: '80vh', overflowX: 'auto' }}
          className='absolute z-10 px-0 mt-3 transform -translate-x-1/2 left-1/2'>
          <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
            <div className='relative flex flex-col px-4 py-2 bg-white'>
              {items.map(({ href, label }) => {
                const isActive = decodeURI(router.asPath) === href
                return (
                  <Link key={href} href={href}>
                    <a
                      onClick={() => setIsOpen(false)}
                      className={`p-2 transition duration-150 my-1 ease-in-out rounded-lg ${
                        isActive ? 'bg-indigo-100' : 'hover:bg-gray-100'
                      }`}>
                      <div>
                        <p className='text-base font-medium text-gray-900'>
                          {capitalizeFirstLetter(label)}
                        </p>
                      </div>
                    </a>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default RegionMenu

interface Props {
  items: { href: string; label: string }[]
}
