import React, { FC } from 'react'

const ContentLayout: FC<Props> = ({ title, children }) => {
  return (
    <div className='relative py-16 overflow-hidden'>
      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='mx-auto text-lg max-w-prose'>
          <h1 className='block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-primary-500 sm:text-4xl'>
            {title}
          </h1>
          <div className='mx-auto mt-6 prose prose-lg text-gray-500 prose-primary-500'>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentLayout

interface Props {
  title: string
}
