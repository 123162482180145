import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, ReactNode, useState } from 'react'

const timeoutLength = 300

const DropdownMenu: FC<Props> = ({ items, label }) => {
  const router = useRouter()
  const [isHoveringButton, setIsHoveringButton] = useState(false)
  const [isHoveringMenu, setIsHoveringMenu] = useState(false)

  const open = isHoveringButton || isHoveringMenu

  return (
    <div className='relative'>
      {/* <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" --> */}
      <button
        aria-owns={open ? 'simple-menu' : null}
        aria-haspopup={true}
        onMouseEnter={() => setIsHoveringButton(true)}
        onMouseLeave={() => {
          setTimeout(() => {
            setIsHoveringButton(false)
          }, timeoutLength)
        }}
        type='button'
        className='inline-flex items-center text-base font-medium text-gray-500 bg-white rounded-md group hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'>
        <span>{label}</span>
        {/* <!--
Heroicon name: solid/chevron-down

Item active: "text-gray-600", Item inactive: "text-gray-400"
--> */}
        <svg
          className='w-5 h-5 ml-2 text-gray-400 group-hover:text-gray-500'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
          fill='currentColor'
          aria-hidden='true'>
          <path
            fillRule='evenodd'
            d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
            clipRule='evenodd'
          />
        </svg>
      </button>

      {/* <!--
Flyout menu, show/hide based on flyout menu state.

Entering: "transition ease-out duration-200"
From: "opacity-0 translate-y-1"
To: "opacity-100 translate-y-0"
Leaving: "transition ease-in duration-150"
From: "opacity-100 translate-y-0"
To: "opacity-0 translate-y-1"
--> */}
      {open && (
        <div
          id='simple-menu'
          onMouseEnter={() => setIsHoveringMenu(true)}
          onMouseLeave={() => {
            setTimeout(() => {
              setIsHoveringMenu(false)
            }, timeoutLength)
          }}
          className='absolute z-10 w-screen max-w-md px-2 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0'>
          <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
            <div className='relative flex flex-col px-5 py-4 bg-white sm:gap-8 sm:p-4'>
              {items.map(({ href, label, Icon }) => {
                const isActive = router.pathname == href
                return (
                  <Link key={href} href={href}>
                    <a
                      className={`flex items-start p-3 transition duration-150 ease-in-out rounded-lg ${
                        isActive ? 'bg-indigo-50' : 'hover:bg-gray-100'
                      }`}>
                      {Icon}
                      <div className='ml-4'>
                        <p className='text-base font-medium text-gray-900'>
                          {label}
                        </p>
                      </div>
                    </a>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DropdownMenu

interface Props {
  items: { href: string; label: string; Icon?: ReactNode }[]
  label: string
}
