import { event } from 'lib/analytics'
import { TestLocation } from 'lib/types'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, useState } from 'react'
import Headroom from 'react-headroom'
import { menuItems } from '../lib/content'
import DropdownMenu from './DropdownMenu'
import RegionMenu from './RegionMenu'

const Header: FC<Props> = ({ locations, location }) => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const [isMenu, setIsMenu] = useState(false)

  return (
    <Headroom>
      <nav className='bg-white shadow'>
        <div className='pl-4 mx-auto sm:pl-6 lg:pl-8'>
          <div className='flex justify-between h-16'>
            <div className='flex items-center'>
              <div className='flex items-center mr-2 -ml-2 md:hidden'>
                <button
                  onClick={() => setIsMenu((prev) => !prev)}
                  type='button'
                  className='inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary'
                  aria-controls='mobile-menu'
                  aria-expanded='false'>
                  <span className='sr-only'>{t('openMenu')}</span>

                  <svg
                    className={`${isMenu ? 'hidden' : 'block'} h-6 w-6`}
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M4 6h16M4 12h16M4 18h16'
                    />
                  </svg>

                  <svg
                    className={`${isMenu ? 'block' : 'hidden'} h-6 w-6`}
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              </div>
              <Link href='/'>
                <a className='flex items-center flex-shrink-0'>
                  <span className='sr-only'>Homepage</span>
                  <div className='block w-auto h-8 lg:hidden'>
                    <img
                      alt='Corona Freepass'
                      src='/logo.svg'
                      height={35}
                      width={35}
                    />
                  </div>
                  <div className='hidden w-auto lg:block'>
                    <img
                      alt='Corona Freepass'
                      src='/banner.png'
                      height={40}
                      width={230}
                    />
                  </div>
                </a>
              </Link>
              {locations && (
                <RegionMenu
                  items={locations
                    .filter(
                      (l) =>
                        !['testone', 'discoverydock'].includes(
                          l.region_name.toLowerCase()
                        )
                    )
                    .map((l) => ({
                      href: `/${l.region_name.toLowerCase()}`,
                      label: l.region_name,
                    }))}
                />
              )}
            </div>

            <div className='flex items-center'>
              <div className='items-center hidden mr-4 md:flex'>
                <DropdownMenu
                  label={t('aboutCoronaPass')}
                  items={menuItems(t)}
                />
              </div>
              <a className='h-full' href='#faq'>
                <button
                  onClick={() =>
                    event({
                      name: 'booking_session_init',
                      location: 'header',
                    })
                  }
                  type='button'
                  className='inline-flex items-center h-full px-4 py-2 text-sm font-medium text-white border-r-2 border-white shadow-sm bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'>
                  <span>Hilfe</span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='w-6 h-6 ml-2'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'>
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                    />
                  </svg>
                </button>
              </a>
              <Link
                href={
                  location
                    ? `/buchen?region=${location.region_name}`
                    : router.query.code
                    ? `/buchen?code=${router.query.code}`
                    : '/buchen'
                }>
                <a className='h-full'>
                  <button
                    onClick={() =>
                      event({
                        name: 'booking_session_init',
                        location: 'header',
                      })
                    }
                    type='button'
                    className='inline-flex items-center h-full px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'>
                    <span>{t('getTested')}</span>
                    <svg
                      className='w-5 h-5 ml-2 -mr-1'
                      fill='none'
                      stroke='currentColor'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M14 5l7 7m0 0l-7 7m7-7H3'></path>
                    </svg>
                  </button>
                </a>
              </Link>
            </div>
          </div>
        </div>

        {isMenu && (
          <div className='md:hidden' id='mobile-menu'>
            <div className='pt-2 pb-3 space-y-1'>
              {menuItems(t).map(({ href, label }) => {
                const isActive = router.pathname == href
                return (
                  <Link key={href} href={href}>
                    <a
                      className={`block py-2 pl-3 pr-4 text-base font-medium ${
                        isActive
                          ? 'text-primary-500 border-indigo-500 bg-indigo-50'
                          : 'border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 text-gray-500'
                      }  border-l-4  sm:pl-5 sm:pr-6`}>
                      {label}
                    </a>
                  </Link>
                )
              })}
            </div>
          </div>
        )}
      </nav>
    </Headroom>
  )
}

export default Header

interface Props {
  locations?: TestLocation[]
  location?: TestLocation
}
