import { useEffect, useRef, useState } from 'react'
import { isServer } from './utils'

export const usePopover = (initialState: boolean) => {
  const [isOpen, setIsOpen] = useState(initialState)
  const ref = useRef(null)

  // listen for click outside of element, source: https://codechips.me/tailwind-ui-react-vs-svelte/
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // @ts-ignore: Object is possibly 'null'.
      if (!ref?.current?.contains(event.target)) {
        if (!isOpen) return
        setIsOpen(false)
      }
    }

    window.addEventListener('click', handleOutsideClick)
    return () => window.removeEventListener('click', handleOutsideClick)
  }, [isOpen, ref])

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!isOpen) return

      if (event.key === 'Escape') {
        setIsOpen(false)
      }
    }

    document.addEventListener('keyup', handleEscape)
    return () => document.removeEventListener('keyup', handleEscape)
  }, [isOpen])

  return { ref, isOpen, setIsOpen }
}

export const useAdjustedViewport = () => {
  useEffect(() => {
    const handleViewport = () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    handleViewport()
    window.addEventListener('resize', handleViewport)

    return () => window.removeEventListener('resize', handleViewport)
  }, [isServer])
}
