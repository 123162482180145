import { TestLocation } from 'lib/types'
import React, { FC } from 'react'
import { Toaster } from 'react-hot-toast'
import ContentLayout from './ContentLayout'
import CookieBanner from './CookieBanner'
import Footer from './Footer'
import Header from './Header'
import Meta from './Meta'

const Layout: FC<Props> = ({
  children,
  title,
  type = 'default',
  locations,
  location,
}) => {
  if (type === 'blank') {
    return (
      <div className='flex flex-col min-h-screen mx-auto max-w-screen-2xl'>
        <Meta title={title} />
        <Toaster />
        <div className='flex flex-col flex-1'>
          <div className='flex-1'>
            <ContentLayout title={title}>{children}</ContentLayout>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-col min-h-screen mx-auto max-w-screen-2xl'>
      <Meta title={title} />
      <div>
        <CookieBanner />
      </div>
      <Toaster />
      <div className='flex flex-col flex-1'>
        <div className='flex-1'>
          <Header location={location} locations={locations} />
          {type === 'content' ? (
            <ContentLayout title={title}>{children}</ContentLayout>
          ) : (
            children
          )}
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Layout

interface Props {
  title?: string
  type?: 'content' | 'default' | 'blank'
  locations?: TestLocation[]
  location?: TestLocation
}
